 <template>  
  <!-- <img alt="Chesspana logo" src="./assets/logo.png">  --->
  <HelloWorld msg="Bienvenidos a Chesspana, la Nueva Plataforma de Ajedrez,
  donde encontraras Noticias actuales y aconteceres que marcaron la historia del ajedrez mundial, 
  y una Arena de Estrategias, Diversion y Adrenalina"/>
  <br>
    <h4> El Arte y la Ciencia Pragmados en la Arena del Conocimiento.! </h4>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}

</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ced1d4;
  margin-top: 0px;
}





/**css de navbar    el color de las letras #dee5ed****/

*{
  margin: 0;
  box-sizing: border-box;
}
 
  
body {
  background-color: #1b2130;
  font-family: sans-serif, Arial, Helvetica, sans-serif;
  padding: 0px 3px 3px 0;    
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
   
  height: 450px;
  color: aliceblue;
  position: relative;
  padding-bottom: 3em;
  min-height: 100vh;
  
}

.nav ul,ol{
  list-style: none;
  text-decoration: none;
  
  }
  
.nav {
    display: flex;
    justify-content: space-around;
    margin-top: 0px;
    
     
}

.nav > ul > li > ul {
    padding: 0;
}

.nav li a {
    text-decoration: none;
    text-align: left;
    color: rgb(240, 234, 234); 
}


  
.nav > li {
  float: left;
  text-align: left;
  text-decoration: none;
}


.nav li ul {
  display: none;
  position: absolute;
  min-width: 30px;  
  text-transform: capitalize; 
}


.nav-menu {
  display: flex;
  margin-right: 40px;
  list-style: none;
  margin-top: 0px;
}


.nav-menu-item {
  font-size: 15px;
  margin: 0 10px;
  line-height: 80px;
  text-transform: uppercase;
  width: max-content;

  /***cambiamos el tamañño o espacio entre las listas****/
  line-height: 40px; 
      


}

.nav-link {
  padding: 2px 3px;
  border-radius: 6px;
}

.nav-link :hover,
.nav-link_active,
.nav li a:hover {
  background-color: rgb(95, 130, 67);
  transition: 0.5s;
  border-radius: 3px;
  
    
}


.nav li:hover > ul {
  display: block;
  text-align: left;
  text-decoration: none;
  border-radius: 6px;
  transition: 0.5s;
  background-color: rgb(133, 99, 52);
    
  
}

/***

.nav-toggle {
  color: aliceblue;
  background: none ;
  border: none;
  font-size: 30px;
  padding: 0 20px;
  line-height: 60px;
  cursor: pointer;

  display: none;    
}
  





  
.Chesspana {
  font-size: 25px;         
  padding: 0 20px;
  line-height: 60px;        
}

.nav-menu {
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  background-color: rgb(175, 138, 36);
  position: fixed;
  left: 0;
  top: 60px;
  width: 100px;
  padding: 20px 0;
  
  height: calc(100% - 60px);
  overflow-y: auto;

  left: 100%;
  transition: lef 0.3s;
}



*/

.nav-menu_visible {
  left: 0;
  
  }

.nav-toggle:focus:not(:focus-visible){
    outline: none;
    
}




@media (max-width: 768px){
  body {
      padding-top: 1px ;
      margin-left: 2px;
      margin-right: 2px;

  }



}


</style>
