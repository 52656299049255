<template>
  <div class="hello">
    <h3>{{ msg }}</h3>
    <p>
      Pagina en Construccion     
    </p>    
  </div>


</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 3px 0 0;
}


ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #70b942;
}


</style>
